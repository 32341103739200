/* stylelint-disable */
body {
  > mat-sidenav-container {
    height: 100%;
  }
}

button {
  outline: none;
}

input::-moz-focus-inner {
  outline: 0;
}

button:-moz-focusring {
  outline: 0;
}

a {
  text-decoration: none !important;
}

@keyframes toast-animation-from-top {
  0% {
    transform: translate3d(0, -100%, 0) skewX(-30deg);
    opacity: 0;
  }

  10% {
    transform: skewX(0deg);
    opacity: 1;
  }

  90% {
    transform: skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -100%, 0) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes toast-animation-from-bottom {
  0% {
    transform: translate3d(0, 100%, 0) skewX(-30deg);
    opacity: 0;
  }

  10% {
    transform: skewX(0deg);
    opacity: 1;
  }

  90% {
    transform: skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0) skewX(-30deg);
    opacity: 0;
  }
}

.toast-container {
  text-align: center;
  display: flex;
  flex-direction: column;

  &.toast-bottom-right {
    right: 0;
    min-width: 400px;
  }

  .toast.custom-toast {
    animation: toast-animation-from-top 4000ms forwards;
    top: 30px;
    margin: 6px auto;
    pointer-events: all;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    background-image: none;
    padding: 8px 20px;
    border-radius: 21px;
    box-shadow: none;
    width: auto;

    &.side-toast {
      animation: toast-animation-from-bottom 4000ms forwards;
      top: -50px;
    }

    &:hover {
      box-shadow: none;
    }

    .toast-message {
      font-family: Nunito, sans-serif;
      font-size: 14px;
      font-weight: 600;
      padding-right: 25px;
    }

    .toast-close-button {
      font-weight: bold;
      font-family: sans-serif;
      font-size: 18px;
      right: 10px;
      position: absolute;
      border-radius: 10px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      line-height: 20px;
    }

    &.toast-success {
      background-color: #e0f8ea;

      .toast-message {
        color: #2ed573;
      }

      .toast-close-button {
        background-color: #c6f3d9;
        color: #2ed573;
      }
    }

    &.toast-error {
      background-color: #fff2ed;

      .toast-message {
        color: #dd4757;
      }

      .toast-close-button {
        background-color: #ffd8d7;
        color: #ff4757;

        .mat-icon {
          color: #ff4757;
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}
