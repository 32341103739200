/* You can add global styles to this file, and also import other style files */

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import 'theme';
@import 'core';

// 3rd party libraries
// @import '~material-design-icons-iconfont/dist/fonts/material-icons.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~ngx-toastr/toastr.css';

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

.fab {
  font-weight: initial;
}

.headway-widget {
  #HW_badge {
    background: $brand;
    font-size: 0;
    width: 12px;
    height: 12px;
    left: 23px;

    &.HW_softHidden {
      opacity: 0;
    }
  }

  #HW_badge_cont {
    height: 50px;
    width: 35px;
  }
}
