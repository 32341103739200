.ps {
  position: relative;

  > .ps__rail-x {
    z-index: 99999;
    width: 8px;
  }

  > .ps__rail-y {
    z-index: 99999;
    // stylelint-disable
    left: auto !important;
    // stylelint-enable
    width: 12px;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 8px;
  }

  .ps__thumb-y {
    width: 4px;
  }
}
