@import '~@angular/material/theming';

$mat-white: (
  500: white,
  contrast: (
    500: $black-87-opacity,
  ),
);

$mat-black: (
  b 500: black,
  contrast: (
    500: white,
  )
);

$mat-rkorange: (
  50: #fff0ea,
  100: #ffd9cb,
  200: #ffbfa8,
  300: #ffa585,
  400: #ff926a,
  500: #ff7f50,
  600: #ff7749,
  700: #ff6c40,
  800: #ff6237,
  900: #ff4f27,
  A100: #fff,
  A200: #fff,
  A400: #ffdbd4,
  A700: #ffc6bb,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

// Palettes
$primary: mat-palette($mat-rkorange);
$accent: mat-palette($mat-light-blue, 600, 400, 700);
$warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat-light-theme($primary, $accent, $warn);

$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

/* prettier-ignore */
$general-typography: mat-typography-config(
  $font-family: 'Nunito, sans-serif',
  $body-2: mat-typography-level(14px, 24px, 600)
);

/* prettier-ignore */
$secondary-typography: mat-typography-config($font-family: 'Nunito, sans-serif');

$gray-lightest: #eee;
$gray-medium: #d9d9d9;
$gray-darkest: #9c9c9c;

$gray-hover: #f9f9f9;

$black: #474747;

$brand: #ff7f50;
$brand-gradient: linear-gradient(45deg, $brand 0%, #ff3648 100%);
$block-shadow: 0 0 5px 0 rgba(177, 177, 177, 0.5);

$brand10percent: rgba(255, 127, 80, 0.1);
$brand20percent: rgba(255, 127, 80, 0.2);

$blue-info: #1e90ff;
$blue-info10percent: rgba(30, 144, 255, 0.1);

$success: #2ed573;
$error: #ff4757;
$equals: #ffa502;
