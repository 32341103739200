/* stylelint-disable */

/* ---------------------------------------------------------------- */

/*  Avatars
/* ---------------------------------------------------------------- */
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #fff;

  &.square {
    border-radius: 0;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px;
  }

  &.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px;
  }
}

.avatar-wrapper {
  position: relative;

  .avatar {
    margin-top: 0;
    margin-bottom: 0;
  }

  mat-icon.status {
    position: absolute;
    top: 28px;
    left: 28px;
  }
}

mat-icon.status {
  border-radius: 50%;

  &.online {
    color: #4caf50;

    &::before {
      content: 'check_circle';
    }
  }

  &.do-not-disturb {
    color: #f44336;

    &::before {
      content: 'do_not_disturb_on';
    }
  }

  &.away {
    background-color: #ffc107;
    color: #fff;

    &::before {
      content: 'access_time';
    }
  }

  &.offline {
    color: #646464;
    background-color: #fff;

    &::before {
      content: 'not_interested';
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Slides
/*---------------------------------------------------------------- */

body .mat-slide-toggle {
  .mat-slide-toggle-thumb-container {
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;

    .mat-slide-toggle-thumb {
      height: 16px;
      width: 16px;
      background-color: #fff;
      box-shadow: 0 0 2px 0 rgba(88, 88, 88, 0.5);
    }
  }

  .mat-slide-toggle-ripple {
    display: none;
  }

  .mat-slide-toggle-bar {
    height: 20px;
    width: 40px;
    border-radius: 10px;
    background-color: $gray-medium;
  }

  &.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fff;
  }

  &.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(19px, 0, 0);
  }

  &.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background: linear-gradient(45deg, #ff7f50 0%, #ff3648 100%);
  }

  .mat-slide-toggle-content {
    color: $black;
    font-weight: 600;
  }
}

/* ---------------------------------------------------------------- */

/*  Checkbox
/*---------------------------------------------------------------- */

body .mat-checkbox {
  font-size: 15px;
  line-height: 15px;
  height: 15px;

  .mat-checkbox-inner-container {
    height: 15px;
    width: 15px;
    font-size: 15px;
    margin-right: 10px;
  }

  .mat-checkbox-ripple {
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    border-radius: 50%;
    opacity: 0;
  }

  .mat-checkbox-frame {
    border-color: $gray-darkest;
    border-width: 1px;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 15px;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    user-select: none;
  }

  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $brand;
  }

  &.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
    background-color: $brand;
    opacity: 0.7;
  }
  &.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-frame {
    border: $brand;
    opacity: 0.7;
  }

  &.mat-checkbox-disabled .mat-checkbox-layout .mat-checkbox-label {
    color: rgba(0, 0, 0, 0.38);
  }
}

body figure .mat-checkbox .mat-checkbox-inner-container,
body .table-head .mat-checkbox .mat-checkbox-inner-container {
  height: 17px;
  width: 17px;
}

/* ---------------------------------------------------------------- */

/*  Radio buttons
/*---------------------------------------------------------------- */
body .mat-radio-group {
  margin-top: 15px;
}

body .mat-radio-button {
  color: $black;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 50px;
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    height: 13px;
    width: 13px;
  }

  .mat-radio-ripple.mat-ripple {
    display: none;
  }

  .mat-radio-outer-circle {
    border-width: 1px;
  }

  &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $brand;
  }

  &.mat-accent.mat-radio-checked .mat-radio-inner-circle,
  &.mat-accent .mat-radio-inner-circle {
    background-color: $brand;
  }
}

/* ---------------------------------------------------------------- */

/*  Tooltips
/*---------------------------------------------------------------- */

.mat-tooltip-panel .mat-tooltip {
  max-width: 260px;
  font-size: 14px;
  margin-bottom: 6px;
  overflow: initial;
  background-color: #818181;

  &::after {
    top: 100%;
    left: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 4px solid rgba(0, 0, 0, 0);
    border-top-color: #818181;
    margin-left: -4px;
  }
}

/* ---------------------------------------------------------------- */

/*  Sliders
/*---------------------------------------------------------------- */

body {
  .fix-slider,
  .max-slider-vertical {
    .mat-slider-thumb {
      transform: scale(0.5) !important;
      border: none;
    }

    .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
      background-color: $brand;
    }

    .mat-slider-track-background {
      background-color: $gray-lightest;
      transform: translateX(4px) scale3d(1, 1, 1) !important;
    }
  }
}

.mat-primary.mat-slider-vertical {
  min-height: 60px;
  width: 20px;

  &.mat-slider-min-value {
    min-height: 56px;
  }

  .mat-slider-thumb {
    background-color: white;
    transform: scale(0.5) !important;
    border: 0;
  }

  .mat-slider-track-background {
    background-color: rgba(255, 255, 255, 0.5);
    transform: translateY(-4px) scale3d(1, 1, 1) !important;
  }

  .mat-slider-wrapper {
    left: 9px;

    .mat-slider-track-wrapper {
      .mat-slider-track-fill {
        background-color: white;
      }
    }
  }
}

.live .mat-primary.mat-slider-vertical {
  min-height: 50px;

  &.mat-slider-min-value {
    min-height: 46px;
  }
}

/* ---------------------------------------------------------------- */

/*  Progress bars / spinners
/*---------------------------------------------------------------- */

.percentage .mat-progress-spinner circle {
  stroke: $blue-info;
}

.headway-widget .mat-progress-spinner circle {
  stroke: $gray-medium;
}

.percentage .mat-progress-spinner[aria-valuenow^='100'] circle {
  stroke: #2ed573;
}

.mat-progress-bar {
  border-radius: 4px;
}

.mat-progress-bar-background {
  fill: $blue-info10percent;
  display: none;
}

.mat-progress-bar-buffer {
  background-color: $blue-info10percent;
}

.mat-progress-bar-fill::after {
  background-color: $blue-info;
}

.mat-progress-bar[aria-valuenow^='100'] .mat-progress-bar-fill::after {
  background-color: #2ed573;
}

/* ---------------------------------------------------------------- */

/*  Buttons
/*---------------------------------------------------------------- */

body {
  .mat-button {
    border-radius: 2px;
    padding: 5px;
    line-height: 20px;
    min-width: 20px;

    &.mat-primary {
      .mat-button-focus-overlay:hover {
      }
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Modals
/*---------------------------------------------------------------- */

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

/* ---------------------------------------------------------------- */

/*  Forms
/*---------------------------------------------------------------- */
.form-wrapper {
  background: #fff;
  padding: 16px;

  .form-title {
    font-size: 21px;
    padding: 8px 0;
  }
}

/* ---------------------------------------------------------------- */

/*  Navigation - Simple
/*---------------------------------------------------------------- */
.navigation-simple {
  .item {
    position: relative;
    cursor: pointer;
    text-align: left;
    margin: 0;
    padding: 0 24px;
    text-transform: none;
    line-height: 48px;
    max-height: 48px;
    height: 48px;

    mat-icon {
      margin: 0 16px 0 0;
    }

    .title {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .subheader {
    font-size: 13px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-weight: 500;
    margin-top: 8px;

    &.light {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  mat-divider {
    margin: 8px 0;
  }
}

body {
  .mat-drawer.mat-drawer-side {
    z-index: 3;
  }
}

/* ---------------------------------------------------------------- */

/*  Pagination
/*---------------------------------------------------------------- */
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;

  @include mat-elevation(1);

  background-color: #fff;
  padding: 0 8px;

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 56px;
    line-height: 56px;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);

    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }

    &.active {
      cursor: default;
      color: rgba(0, 0, 0, 0.87);
    }

    &.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Menu
/*---------------------------------------------------------------- */

.mat-menu-panel {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  min-height: 46px !important;

  .mat-menu-content {
    padding-top: 4px;
    padding-bottom: 4px;

    .mat-icon {
      @include sizeIcon(10px);

      color: $gray-darkest !important;
      margin-right: 10px;

      &.danger {
        color: $error !important;
      }
    }

    .mat-divider {
      border-top-color: #eee;
      margin: 4px 0 4px 30px;
    }

    .danger {
      color: $error;
    }

    .mat-menu-item {
      color: $black;
      font-size: 14px;
      line-height: 19px;
      height: initial;
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 15px;

      &:first-child {
        padding-top: 7px;
      }

      &:last-child {
        padding-bottom: 7px;
      }
    }

    .menu-separator {
      width: calc(100% - 30px);
      height: 1px;
      display: block;
      background-color: $gray-lightest;
      content: '';
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.mat-menu-panel.user-mat-menu {
  width: 290px;
  border-radius: 2px;

  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 15px;
  }

  .mat-divider {
    margin-left: 15px;
    margin-bottom: 8px;
  }

  .mat-menu-item {
    padding-left: 15px;
    height: 35px;
  }
}

.mat-menu-panel.user-mat-menu {
  min-width: 200px;
}

.mat-menu-panel.radio-mat-menu {
  margin-top: 10px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    margin-right: 10px;
  }

  .mat-menu-content .mat-menu-item {
    height: 45px;
  }
}

.mat-menu-panel.logout-mat-menu {
  min-width: 250px;
  margin-top: 10px;

  .mat-menu-item {
    height: 40px;
    font-size: 16px;

    .mat-icon {
      @include sizeIcon(16px);

      color: $black !important;
    }
  }
}

.radio-incoming-songs-playing-menu {
  width: 320px;
  max-width: 320px;
  min-width: 320px;

  .mat-menu-content {
    padding: 5px;
  }
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 0 5px 0 rgba(177, 177, 177, 0.5) !important;
}

mat-sidenav .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: initial;
}

/* ---------------------------------------------------------------- */

/*  Table - Simple
/*---------------------------------------------------------------- */
.simple-table-container {
  background: #fff;

  .table-title {
    font-size: 20px;
    padding: 24px;
  }
}

table {
  &.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;

    thead {
      tr {
        th {
          padding: 16px 8px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.54);
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          white-space: nowrap;

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 16px 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    &.clickable {
      tbody {
        tr {
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }
      }
    }
  }
}

rk-header .mat-button-focus-overlay {
  opacity: 0 !important;
}
